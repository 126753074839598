import cn from 'classnames';
import React from 'react';

import styles from './MainFooterSocial.module.scss';

type Props = {
  icon: React.ReactNode;
  link: string;
  className: string;
};

export const MainFooterSocial: React.FC<Props> = ({ icon, link, className }) => {
  return (
    <a
      href={link}
      target="_blank"
      className={cn(styles.footerSocial, className)}
      rel="noopener noreferrer"
      aria-label="social link"
    >
      {icon}
    </a>
  );
};
