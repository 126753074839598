import { IAmazonBookDetailed, IAmazonBooks } from '../models/IAmazon';
import { rootApi } from './rootApi';

export const amazonApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getAmazonBooks: builder.query<IAmazonBooks, void>({
      query: () => '/blog/get-amazon-books/',
    }),
    getAmazonBook: builder.query<IAmazonBookDetailed, string>({
      query: slug => `/blog/get-amazon-books/${slug}`,
    }),
  }),
});

export const { useGetAmazonBooksQuery, useGetAmazonBookQuery } = amazonApi;
