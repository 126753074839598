import { lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes as DomRoutes, useLocation, useNavigate } from 'react-router-dom';

import { Loader } from './components/Loader';
import { RequireAuth } from './components/RequireAuth';
import { useCanonicalTags } from './hooks/useCanonicalTags';
import { useScrollTop } from './hooks/useScrollTop';
import { useTypedSelector } from './hooks/useTypedSelector';
import { PageLayout } from './layouts/PageLayout/PageLayout';
import { Courses } from './pages/Courses';
import { Home } from './pages/Home/Home';
import { Product } from './pages/Product';
import { Products } from './pages/Products';
import { selectIsRedirectToSignIn, setRedirectToSignIn, setUser } from './redux/Auth';
import { PAGES_ENUM } from './utils/constants';
import { isCourseBlockVisible } from './utils/temporary';

const AboutUs = lazy(() => import(/* webpackChunkName: "lazy-chunk.about" */ './pages/AboutUs'));
const MyProfile = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.profile" */ './pages/MyProfile')
);
const SignIn = lazy(() => import(/* webpackChunkName: "lazy-chunk.sign_in" */ './pages/SignIn'));
const SignUp = lazy(() => import(/* webpackChunkName: "lazy-chunk.sign_up" */ './pages/SignUp'));
const Blog = lazy(() => import(/* webpackChunkName: "lazy-chunk.blogs" */ './pages/Blog'));
const OurStaff = lazy(() => import(/* webpackChunkName: "lazy-chunk.staff" */ './pages/OurStaff'));
const Webinar = lazy(() => import(/* webpackChunkName: "lazy-chunk.webinars" */ './pages/Webinar'));
const ArticleDetails = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.blog_details" */ './pages/ArticleDetails')
);
const WebinarDetails = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.webinar_details" */ './pages/WebinarDetails')
);
const MyProducts = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.workbooks" */ './pages/MyProducts')
);
const MyCourses = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.courses" */ './pages/MyCourses')
);
const FAQ = lazy(() => import(/* webpackChunkName: "lazy-chunk.faq" */ './pages/FAQ'));
const Cart = lazy(() => import(/* webpackChunkName: "lazy-chunk.cart" */ './pages/Cart'));
const VideoCourse = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.course" */ './pages/VideoCourse')
);
const VideoLesson = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.videolesson" */ './pages/VideoLesson')
);
const Collections = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.collections" */ './pages/Collections')
);
const Collection = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.collection" */ './pages/Collection')
);
const CollectionProducts = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.col_p" */ './pages/CollectionProducts')
);
const CollectionCourses = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.col_c" */ './pages/CollectionCourses')
);
const OrdersHistory = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.ord_history" */ './pages/OrdersHistory')
);
const Favorites = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.favorites" */ './pages/Favorites')
);
const StaffDetails = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.staff_details" */ './pages/StaffDetails')
);
const FavoriteProducts = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.fav_p" */ './pages/FavoriteProducts')
);
const FavoriteCourses = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.fav_c" */ './pages/FavoriteCourses')
);
const Order = lazy(() => import(/* webpackChunkName: "lazy-chunk.order" */ './pages/Order'));
const PrivacyPolicy = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.priv_policy" */ './pages/PrivacyPolicy')
);
const Copyright = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.copyright" */ './pages/Copyright')
);
const ContactUs = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.contact" */ './pages/ContactUs')
);
const TermsOfService = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.terms" */ './pages/TermsOfService')
);
const Info = lazy(() => import(/* webpackChunkName: "lazy-chunk.info" */ './pages/Info'));
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.forg_p" */ './pages/ForgotPassword')
);
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.res_p" */ './pages/ResetPassword')
);
const ThankYou = lazy(() => import(/* webpackChunkName: "lazy-chunk.thank" */ './pages/ThankYou'));
const DeleteAccount = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.delete_account" */ './pages/DeleteAccount')
);
const NotFound = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.not_found" */ './pages/NotFound')
);
const AmazonWorkbooksCatalog = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.not_found" */ './pages/AmazonWorkbooksCatalog')
);
const AmazonWorkbook = lazy(
  () => import(/* webpackChunkName: "lazy-chunk.not_found" */ './pages/AmazonWorkbook')
);

export const Routes = () => {
  const user = localStorage.getItem('user') ?? sessionStorage.getItem('user');
  const dispatch = useDispatch();
  const location = useLocation();
  const isCourseBlockAvailable = isCourseBlockVisible();

  useScrollTop();

  useCanonicalTags();

  useEffect(() => {
    user && dispatch(setUser(JSON.parse(user)));
  }, []);

  const navigate = useNavigate();
  const isRedirectToSignIn = useTypedSelector(selectIsRedirectToSignIn);

  useEffect(() => {
    if (!isRedirectToSignIn || location.pathname === PAGES_ENUM.SIGN_IN) return;
    navigate(PAGES_ENUM.SIGN_IN);
    dispatch(setRedirectToSignIn(false));
  }, [isRedirectToSignIn]);

  return (
    <Suspense fallback={<Loader />}>
      <DomRoutes>
        <Route path="/" element={<PageLayout />}>
          <Route element={<RequireAuth />}>
            <Route path={PAGES_ENUM.MY_PRODUCTS} element={<MyProducts />} />
            <Route path={PAGES_ENUM.CART} element={<Cart />} />
            <Route path={PAGES_ENUM.COLLECTIONS} element={<Collections />} />
            <Route path={PAGES_ENUM.COLLECTION} element={<Collection />} />
            <Route path={PAGES_ENUM.COLLECTION_PRODUCTS} element={<CollectionProducts />} />
            <Route path={PAGES_ENUM.MY_PROFILE} element={<MyProfile />} />
            <Route path={PAGES_ENUM.FAVORITES} element={<Favorites />} />
            <Route path={PAGES_ENUM.FAVORITES_PRODUCTS} element={<FavoriteProducts />} />
            <Route path={PAGES_ENUM.ORDERS_HISTORY} element={<OrdersHistory />} />
            <Route path={PAGES_ENUM.ORDER} element={<Order />} />
            {isCourseBlockAvailable && (
              <>
                <Route path={PAGES_ENUM.MY_COURSES} element={<MyCourses />} />
                <Route path={PAGES_ENUM.LESSON} element={<VideoLesson />} />
                <Route path={PAGES_ENUM.COLLECTION_COURSES} element={<CollectionCourses />} />
                <Route path={PAGES_ENUM.FAVORITES_COURSES} element={<FavoriteCourses />} />
              </>
            )}
          </Route>
          <Route path={PAGES_ENUM.HOME} element={<Home />} />
          <Route path={PAGES_ENUM.ABOUT} element={<AboutUs />} />
          <Route path={PAGES_ENUM.SIGN_IN} element={<SignIn />} />
          <Route path={PAGES_ENUM.SIGN_UP} element={<SignUp />} />
          <Route path={PAGES_ENUM.ONLINE_GAMES} element={<Blog />} />
          <Route path={PAGES_ENUM.ONLINE_LESSONS} element={<Webinar />} />
          <Route path={PAGES_ENUM.ONLINE_LESSON_DETAILS} element={<WebinarDetails />} />
          <Route path={PAGES_ENUM.ONLINE_GAME_DETAILS} element={<ArticleDetails />} />
          <Route path={PAGES_ENUM.OUR_STAFF} element={<OurStaff />} />
          <Route path={PAGES_ENUM.PRODUCT} element={<Product />} />
          <Route path={PAGES_ENUM.PRODUCTS} element={<Products />} />
          <Route path={PAGES_ENUM.FAQ} element={<FAQ />} />
          <Route path={PAGES_ENUM.STAFF_DETAILS_PARAMETRIZED} element={<StaffDetails />} />
          <Route path={PAGES_ENUM.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={PAGES_ENUM.COPYRIGHT} element={<Copyright />} />
          <Route path={PAGES_ENUM.CONTACT_US} element={<ContactUs />} />
          <Route path={PAGES_ENUM.TERMS_OF_SERVICE} element={<TermsOfService />} />
          <Route path={PAGES_ENUM.INFO} element={<Info />} />
          <Route path={PAGES_ENUM.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={PAGES_ENUM.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={PAGES_ENUM.THANK_YOU} element={<ThankYou />} />
          <Route path={PAGES_ENUM.DELETE_ACCOUNT} element={<DeleteAccount />} />
          <Route path={PAGES_ENUM.AMAZON_WORKBOOKS} element={<AmazonWorkbooksCatalog />} />
          <Route path={PAGES_ENUM.AMAZON_WORKBOOK} element={<AmazonWorkbook />} />
          {isCourseBlockAvailable && (
            <>
              <Route path={PAGES_ENUM.COURSES} element={<Courses />} />
              <Route path={PAGES_ENUM.COURSE} element={<VideoCourse />} />
            </>
          )}
        </Route>
        <Route path={PAGES_ENUM.NOT_FOUND_404} element={<NotFound />} />
      </DomRoutes>
    </Suspense>
  );
};
