import images from '../assets/img/index';

export enum PAGES_ENUM {
  HOME = '/',
  ABOUT = '/about',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  ONLINE_GAMES = '/online-games',
  ONLINE_GAME_DETAILS = '/online-games/:id',
  OUR_STAFF = '/our-staff',
  ONLINE_LESSONS = '/kidsonlinelessons',
  PRODUCT = '/workbooks/:id',
  PRODUCTS = '/workbooks',
  ONLINE_LESSON_DETAILS = '/kidsonlinelessons/:id',
  MY_PRODUCTS = '/my-workbooks',
  COURSES = '/courses',
  MY_COURSES = '/my-courses',
  FAQ = '/faq',
  CART = '/cart',
  COURSE = '/course/:id',
  LESSON = '/course/:courseId/lesson/:id',
  COLLECTIONS = '/collections',
  COLLECTION = '/collections/:id',
  COLLECTION_PRODUCTS = '/collections/:id/workbooks',
  MY_PROFILE = '/my-profile',
  COLLECTION_COURSES = '/collections/:id/courses',
  FAVORITES = '/favorites',
  FAVORITES_PRODUCTS = '/favorites/workbooks',
  FAVORITES_COURSES = '/favorites/courses',
  ORDERS_HISTORY = '/orders-history',
  ORDER = '/orders-history/:id',
  STAFF_DETAILS = '/staff-details',
  STAFF_DETAILS_PARAMETRIZED = '/staff-details/:id',
  PRIVACY_POLICY = '/privacy-policy',
  COPYRIGHT = '/copyright',
  CONTACT_US = '/contact-us',
  TERMS_OF_SERVICE = '/terms-of-service',
  INFO = '/info/:slug',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  THANK_YOU = '/thank-you',
  DELETE_ACCOUNT = '/destroy-account',
  AMAZON_WORKBOOK = '/amazon-childrens-books/:slug',
  AMAZON_WORKBOOKS = '/amazon-childrens-books',
  NOT_FOUND_404 = '*',
}

export const amazonWorkbooksList = [
  {
    id: 1,
    title: 'Kitten Preschool',
    description:
      'It is an engaging and colorful workbook designed for children aged 5 to 8. It covers essential skills like reading, writing, math, and more, all presented in a way that makes learning enjoyable. With activities ranging from puzzles and games to drawing and coloring, this workbook helps children build confidence and develop problem-solving abilities. The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds<br /><br />The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds',
    video_link: '',
    price: '26.99$',
    cover: images.firstBookCoverImage,
    attachments: [
      images.firstBookAttachmentOne,
      images.firstBookAttachmentTwo,
      images.firstBookAttachmentThree,
      images.firstBookAttachmentFour,
    ],
  },
  {
    id: 2,
    title: 'Under the Sea',
    description:
      'It is an engaging and colorful workbook designed for children aged 5 to 8. It covers essential skills like reading, writing, math, and more, all presented in a way that makes learning enjoyable. With activities ranging from puzzles and games to drawing and coloring, this workbook helps children build confidence and develop problem-solving abilities. The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds<br /><br />The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds',
    video_link: '',
    price: '10.99$',
    cover: images.secondBookCoverImage,
    attachments: [
      images.secondBookAttachmentOne,
      images.secondBookAttachmentTwo,
      images.secondBookAttachmentThree,
      images.secondBookAttachmentFour,
    ],
  },
  {
    id: 3,
    title: 'Kitten Preschool',
    description:
      'It is an engaging and colorful workbook designed for children aged 5 to 8. It covers essential skills like reading, writing, math, and more, all presented in a way that makes learning enjoyable. With activities ranging from puzzles and games to drawing and coloring, this workbook helps children build confidence and develop problem-solving abilities. The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds<br /><br />The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds',
    video_link: '',
    price: '34.99$',
    cover: images.firstBookCoverImage,
    attachments: [
      images.firstBookAttachmentOne,
      images.firstBookAttachmentTwo,
      images.firstBookAttachmentThree,
      images.firstBookAttachmentFour,
    ],
  },
  {
    id: 4,
    title: 'Under the Sea',
    description:
      'It is an engaging and colorful workbook designed for children aged 5 to 8. It covers essential skills like reading, writing, math, and more, all presented in a way that makes learning enjoyable. With activities ranging from puzzles and games to drawing and coloring, this workbook helps children build confidence and develop problem-solving abilities. The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds<br /><br />The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds',
    video_link: '',
    price: '45.99$',
    cover: images.secondBookCoverImage,
    attachments: [
      images.secondBookAttachmentOne,
      images.secondBookAttachmentTwo,
      images.secondBookAttachmentThree,
      images.secondBookAttachmentFour,
    ],
  },
  {
    id: 5,
    title: 'Kitten Preschool',
    description:
      'It is an engaging and colorful workbook designed for children aged 5 to 8. It covers essential skills like reading, writing, math, and more, all presented in a way that makes learning enjoyable. With activities ranging from puzzles and games to drawing and coloring, this workbook helps children build confidence and develop problem-solving abilities. The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds<br /><br />The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds',
    video_link: '',
    price: '26.99$',
    cover: images.firstBookCoverImage,
    attachments: [
      images.firstBookAttachmentOne,
      images.firstBookAttachmentTwo,
      images.firstBookAttachmentThree,
      images.firstBookAttachmentFour,
    ],
  },
  {
    id: 6,
    title: 'Under the Sea',
    description:
      'It is an engaging and colorful workbook designed for children aged 5 to 8. It covers essential skills like reading, writing, math, and more, all presented in a way that makes learning enjoyable. With activities ranging from puzzles and games to drawing and coloring, this workbook helps children build confidence and develop problem-solving abilities. The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds<br /><br />The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds',
    video_link: '',
    price: '10.99$',
    cover: images.secondBookCoverImage,
    attachments: [
      images.secondBookAttachmentOne,
      images.secondBookAttachmentTwo,
      images.secondBookAttachmentThree,
      images.secondBookAttachmentFour,
    ],
  },
  {
    id: 7,
    title: 'Kitten Preschool',
    description:
      'It is an engaging and colorful workbook designed for children aged 5 to 8. It covers essential skills like reading, writing, math, and more, all presented in a way that makes learning enjoyable. With activities ranging from puzzles and games to drawing and coloring, this workbook helps children build confidence and develop problem-solving abilities. The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds<br /><br />The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds',
    video_link: '',
    price: '34.99$',
    cover: images.firstBookCoverImage,
    attachments: [
      images.firstBookAttachmentOne,
      images.firstBookAttachmentTwo,
      images.firstBookAttachmentThree,
      images.firstBookAttachmentFour,
    ],
  },
  {
    id: 8,
    title: 'Under the Sea',
    description:
      'It is an engaging and colorful workbook designed for children aged 5 to 8. It covers essential skills like reading, writing, math, and more, all presented in a way that makes learning enjoyable. With activities ranging from puzzles and games to drawing and coloring, this workbook helps children build confidence and develop problem-solving abilities. The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds<br /><br />The bright illustrations and friendly characters make each lesson feel like an adventure, ensuring that kids stay motivated and eager to learn. Perfect for both home and classroom use, this workbook is an excellent tool for fostering a love of learning in young minds',
    video_link: '',
    price: '45.99$',
    cover: images.secondBookCoverImage,
    attachments: [
      images.secondBookAttachmentOne,
      images.secondBookAttachmentTwo,
      images.secondBookAttachmentThree,
      images.secondBookAttachmentFour,
    ],
  },
];
