import { useTranslation } from 'react-i18next';

import { AmazonBooksCarousel } from '../../../../components/AmazonBooksCarousel';
import { EmptyContentMessage } from '../../../../components/EmptyContentMessage';
import { useGetAmazonBooksQuery } from '../../../../services/amazon';
import { PAGES_ENUM } from '../../../../utils/constants';
import { AmazonWorkbookCard } from '../../../AmazonWorkbooksCatalog/AmazonWorkbookCard';
import { HomeSectionLayout } from '../HomeSectionLayout';
import styles from './AmazonBooks.module.scss';

export const AmazonBooks = () => {
  const { t } = useTranslation();

  const { data } = useGetAmazonBooksQuery();
  const amazonBooks = data?.results || [];

  return (
    <HomeSectionLayout
      title={t('home.amazonBooks.title')}
      description={t('home.amazonBooks.description')}
      buttonLink={amazonBooks.length > 0 ? PAGES_ENUM.AMAZON_WORKBOOKS : undefined}
      buttonText={t('home.amazonBooks.buttonText')}
    >
      {amazonBooks.length > 0 ? (
        <>
          <AmazonBooksCarousel>
            {amazonBooks.slice(0, 4).map(item => {
              const { title, pk, image, slug, price } = item;

              return (
                <AmazonWorkbookCard
                  className={styles.amazonBookCard}
                  key={pk}
                  id={pk}
                  title={title}
                  price={price}
                  cover={image}
                  slug={slug}
                />
              );
            })}
          </AmazonBooksCarousel>
          <div className={styles.amazonBooksDesktopWrapper}>
            {amazonBooks.slice(0, 4).map(item => {
              const { title, pk, image, slug, price } = item;

              return (
                <AmazonWorkbookCard
                  className={styles.amazonBookCard}
                  key={pk}
                  id={pk}
                  title={title}
                  price={price}
                  cover={image}
                  slug={slug}
                />
              );
            })}
          </div>
        </>
      ) : (
        <EmptyContentMessage title="Our books are coming soon! Check back for exciting activity books on Amazon." />
      )}
    </HomeSectionLayout>
  );
};
