import { FacebookIcon } from '../../../../../../assets/svg/FacebookIcon';
import { InstagramIcon } from '../../../../../../assets/svg/InstagramIcon';
import { PinterestIcon } from '../../../../../../assets/svg/PinterestIcon';
import { YoutubeIcon } from '../../../../../../assets/svg/YoutubeIcon';
import { MainFooterSocial } from './MainFooterSocial/MainFooterSocial';
import styles from './MainFooterSocials.module.scss';

const mainFooterSocials = [
  {
    id: 'facebook',
    link: 'https://www.facebook.com/smartkids.online.learning/',
    icon: FacebookIcon,
    className: 'social-facebook',
  },
  {
    id: 'instagram',
    link: 'https://www.instagram.com/smart_kids_online/',
    icon: InstagramIcon,
    className: 'social-instagram',
  },
  {
    id: 'pinterest',
    link: 'https://www.pinterest.com/SmartKidsOnline/',
    icon: PinterestIcon,
    className: 'social-pinterest',
  },
  {
    id: 'youtube',
    link: 'https://www.youtube.com/@SmartKids-Online',
    icon: YoutubeIcon,
    className: 'social-youtube',
  },
];

export const MainFooterSocials = () => {
  return (
    <div className={styles.mainFooterSocials}>
      {mainFooterSocials.map(social => {
        const { id, icon: Icon, link, className } = social;

        return (
          <MainFooterSocial
            key={id}
            icon={<Icon className={styles.socialIcon} />}
            link={link}
            className={className}
          />
        );
      })}
    </div>
  );
};
