import cn from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGES_ENUM } from '../../../utils/constants';
import styles from './AmazonWorkbookCard.module.scss';

interface Props {
  id: number;
  title: string;
  price: string;
  cover: string;
  className?: string;
  slug: string;
}

export const AmazonWorkbookCard: FC<Props> = ({ id, title, cover, className, slug, price }) => {
  const navigate = useNavigate();

  return (
    <div
      className={cn(styles.card, className)}
      onClick={() => navigate(`${PAGES_ENUM.AMAZON_WORKBOOKS}/${slug}`)}
    >
      <img src={cover} alt="cover" />
      <h3>{title}</h3>
      <span>{price}&#36;</span>
    </div>
  );
};
